@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700&subset=cyrillic,cyrillic-ext');

body, html {
  height: 100%;
}
.list-controls {
  margin: 20px 0;
}

ng2-auto-complete {
  width: auto !important;
}

.scrolled-content .ng-scroll-content {
    display: block;
}

body{
  font-family: "Open Sans";
  background: #FAFAFA;
  overflow-x: hidden;
}
.no-padding{
  padding: 0;
}
.col-centered
{
  margin: 0 auto;
  float:none;
}
.white-row
{
  background: #ffffff;
  padding-bottom: 16px;
}
.mar16top
{
  margin-top: 16px;
  margin-bottom: 16px;
}
.nav-shadow
{
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.1);
}
.flex-centered
{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  line-height: 18px;
}
.save-btn
{
  height: 38px;
  width: 112px;
  background: #6FCF97;
  border: 1px solid rgba(0,0,0,0.25);
  text-align: center;
  line-height: 100%;
  font-weight: 600;
  font-size: 13px;
  color: #ffffff;
  border-radius: 4px;

  &:disabled {
    background: rgba(111, 207, 151, 0.42);
  }
}
.reject-btn
{
  height: 38px;
  width: 112px;
  background: white;
  border: 1px solid rgba(0,0,0,0.25);
  text-align: center;
  line-height: 100%;
  font-weight: 600;
  font-size: 13px;
  color: black;
  border-radius: 4px;
  cursor: pointer;
}
.add-btn, .edit-btn
{
  height: 38px;
  width: 112px;
  background: #2d9cdb;
  border: 1px solid rgba(0,0,0,0.25);
  text-align: center;
  line-height: 100%;
  font-weight: 600;
  font-size: 13px;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}
.remove-btn, .control-btn
{
  height: 38px;
  width: 38px;
  background: #e5e5e5;
  border: 1px solid rgba(0,0,0,0.25);
  text-align: center;
  line-height: 100%;
  font-weight: 600;
  font-size: 13px;
  color: #000;
  border-radius: 4px;
  cursor: pointer;
}
.edit-btn
{
  width: 80px;
}
.cancel-btn
{
  background: rgba(0,0,0,0.2);

  &:disabled {
    background: rgba(0,0,0,0.1);
  }
}
.mar8top {
  margin-top: 8px;
}
.dropdown-menu
{
  position: absolute;
  top: 40px;
  left: -16px;
  z-index: 999;
}
.btn-box
{
  height: 100%;
}
.dropdown-menu
{
  border-bottom: 1px solid;
}
.no-shadow
{
  box-shadow: none !important;
}
.mover-nav-8-padding
{
  padding: 0px 8px;
}
.date-control .ng2-datetime-picker .days {
  width: 240px;
}

.modal {
  z-index: 500000001;
}

.modal-backdrop {
  z-index: 500000000;
}

.tier.unknown {
  background-color: white;
}

.tier.mini2, .tier.fix1 {
  background-color: #FFEDCB;
}

.tier.standart2, .tier.fix2 {
  background-color: #DFE6F4;
}

.tier.extra2, .tier.fix3 {
  background-color: #EAF7F4;
}

.tier.maxi2, .tier.fix4 {
  background-color: #F3EAF7;
}

.pre-wrap {
  white-space: pre-wrap;
}

@media screen and (max-width: 1280px){
  .col-centered
  {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.wide-tooltip .tooltip-inner {
  width: 200px;
  text-align: left;
}

.no-margin {
  margin: 0;
}

.cargo-label {
  font-size: 12px !important;
  color: grey !important;
  padding: 4px;
  background-color: white;
  border: 1px dashed gray;
}

.promo-code {
  font-size: 80%;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #99d9ff;
  color: black;
  display: inline-block;
}

.lightbox {
  z-index: 600000001 !important;
}

.lightboxOverlay {
  z-index: 600000000 !important;
}

.ng-autocomplete.address .is-accuracy input {
  background-color: #6FCF97;
}

.ng-autocomplete.address .invalid input {
  border: 1px solid red;
}

.le-autocomplete .autocomplete-container .suggestions-container {
  ul {

    li {
      & > div {
        display: flex;
        flex-direction: row;

        padding: 14px 15px;
        cursor: pointer;

        a {
          text-decoration: none;
          color: #333;
          font-size: 15px;
          padding: 0;
        }
        .inn {
          padding-left: 5px;
        }
      }
    }

  }
}

/**
 * Хак для яндекс-браузера 23.7.1.1215.
 * По какой-то причине блок карты <map> перекрывает часть =\ элементов интерфейса, находящихся ниже во вложенных тегах
 **/
table, form {
  position: relative;
  z-index: 2;
}

.shake-horizontal {
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) 0s 1 both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-9-13 13:19:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
@keyframes shake-horizontal {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(8px);
  }
  90% {
    transform: translateX(-8px);
  }
}

.infinite-shake-top {
  animation: infinite-shake-top 3.2s cubic-bezier(0.455, 0.030, 0.515, 0.955) 5000ms infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-3-7 16:16:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-top
 * ----------------------------------------
 */
@keyframes infinite-shake-top {
  0%,
  50%,
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 0;
  }
  5% {
    transform: rotate(4deg);
  }
  10%,
  20%,
  30% {
    transform: rotate(-6deg);
  }
  15%,
  25%,
  35% {
    transform: rotate(6deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  45% {
    transform: rotate(4deg);
  }
}
